import {
  signInWithGoogle,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword
} from "../services/firebase";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import logo from "./logo_white.png"

import React, { useState } from "react";

import "../App.css";

const Login = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  // const loadCompany1Credentials = () => {
  //   setLoginEmail("user1@adventureworks.com");
  //   setLoginPassword("Pn7RjoKOeTx4D5A");
  // };
  
  // const loadCompany2Credentials = () => {
  //   setLoginEmail("user1@northwindtraders.com");
  //   setLoginPassword("KDCAtK5qqmtLyfb");
  // };
  const loadCompany1Credentials = () => {
    setLoginEmail("john@adventureworks.com");
    setLoginPassword("AdventureWorks@2023");
  };
  
  const loadCompany2Credentials = () => {
    setLoginEmail("john@northwindtraders.com");
    setLoginPassword("NorthWindTraders@2023");
  };

  return (
    <div>
    <div className="container-fluid" style={{ marginTop: "2.5%" }}>
      <div className="row">
        <div className="col" style={{margin: "5%", marginBottom: "6%", textAlign: "center"}}>
          <img src={logo} width={"250px"} style={{float: "inherit"}}></img>
        </div>
      </div>
      <div className="row">
        <div className="col col-4"></div>
        <div className="col col-8">
          <div className="row">
            <div className="col col-6">
              <Card style={{backgroundColor: '#ffffff', borderColor: '#ffffff'}}>
                <Card.Body>
                  <Card.Title style={{color: '#000000'}} >Login</Card.Title>
                  <div>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{color: '#000000'}} >Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={loginEmail}
                        onChange={(e) => setLoginEmail(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label style={{color: '#000000'}} >Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={loginPassword}
                        onChange={(e) => setLoginPassword(e.target.value)}
                      />
                    </Form.Group>
                    <hr style={{ margin: "5%" }} />
                    <div className="d-flex justify-content-between">
                      <Button
                        style={{backgroundColor: '#FFFFFF', borderColor: '#4CCDD0', width: '49%', color: '#4CCDD0'}}
                        type="button"
                        onClick={loadCompany1Credentials}
                        onMouseOver={(e) => {
                          e.target.style.backgroundColor = '#EFEFEF';
                          e.target.style.borderColor = '#3AB9C2';
                        }}
                        onMouseOut={(e) => {
                          e.target.style.backgroundColor = '#FFFFFF';
                          e.target.style.borderColor = '#4CCDD0';
                        }}
                      >
                        Load Company 1 Credentials
                      </Button>

                      <Button
                        style={{backgroundColor: '#FFFFFF', borderColor: '#EA4C75', width: '49%', color: '#EA4C75'}}
                        type="button"
                        onClick={loadCompany2Credentials}
                        onMouseOver={(e) => {
                          e.target.style.backgroundColor = '#EFEFEF';
                          e.target.style.borderColor = '#D94367';
                        }}
                        onMouseOut={(e) => {
                          e.target.style.backgroundColor = '#FFFFFF';
                          e.target.style.borderColor = '#EA4C75';
                        }}
                      >
                        Load Company 2 Credentials
                      </Button>
                    </div>
                    <div className="d-grid gap-2 mt-3">
                      <Button
                        style={{backgroundColor: '#3796F4', borderColor: '#3796F4'}}
                        type="submit"
                        onClick={() => {
                          signInWithEmailAndPassword(loginEmail, loginPassword);
                        }}
                        onMouseOver={(e) => {
                          e.target.style.backgroundColor = '#2A86D9';
                          e.target.style.borderColor = '#2A86D9';
                        }}
                        onMouseOut={(e) => {
                          e.target.style.backgroundColor = '#3796F4';
                          e.target.style.borderColor = '#3796F4';
                        }}
                      >
                        Login
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-col-4"></div>
      </div>
    </div>
    <div class="container"  style={{marginTop: "16.5%"}}>
      <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <div class="col-md-4 d-flex align-items-center">
          <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
          </a>
          <span class="mb-3 mb-md-0 text-muted">&copy; Copyright 2023 GA4GPT</span>
        </div>
    
        <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li class="ms-3"><a class="text-muted" href="https://ga4gpt.com"><i class="bi bi-link-45deg"></i></a></li>
        </ul>
      </footer>
    </div>
  </div>
  );
};

export default Login;

